import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/north-park-san-diego-housing-market";

  return (
    <LayoutWrapper
      title="What Makes San Diego’s North Park an Attractive Real Estate Market? | The Selby Team"
      desc="Invest, explore and thrive in North Park. Uncover top properties, vibrant local life, and find a lucrative investment opportunity in this San Diego neighborhood."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              What Makes San Diego’s North Park an Attractive Real Estate Market?
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By Sarah Harris
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Oct 23, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_What Makes North Park an Attractive Real Estate Market.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you love bustling neighborhoods bursting with historic architecture,{" "}
                <a
                  href="https://selbysellssd.com/san-diego-communities/north-park-homes-for-sale"
                  target="_Blank"
                  className="text-blue-500"
                >
                  North Park
                </a>{" "}
                is certainly worth a look. A revitalized community located in mid-city San Diego,
                North Park is a cultural melting pot with something for just about everyone.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                With that said, here are some of the factors shaping the North Park real estate
                market:
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                It’s in a Prime Location
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Most people prefer living in walkable areas, and if you plan to live in North Park,
                attractive real estate guarantees you’ll have access to ample sidewalks and bike
                lanes. The neighborhood offers a range of public transportation options, allowing
                you to walk, bike, or ride the bus to work, school, restaurants, and any other
                locales, and it is also minutes away from Interstates 8 and 805 and the San Diego
                International Airport.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                It’s Safe
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Ten to fifteen years ago, the North Park neighborhood was much different than it is
                now: crime was rampant, and many historic buildings, like the Observatory, were
                either abandoned or in a state of disrepair.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Thankfully, as more businesses opened and people have flocked to the area, it’s
                reached a new level of vitality. Most recently, North Park has seen a dramatic
                decrease in crime, being significantly safer than many areas of San Diego.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                It Offers Plenty of Outdoor Activities
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                It isn’t every day that you find a neighborhood so universally close to mountains,
                beaches, and woodland trails, but if you live in North Park, you can easily get to
                all of them!
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                In addition, like all San Diego neighborhoods, North Park has plenty of outdoor
                attractions, one of which is Bird Park, a peaceful green space perfect for picnics
                and afternoon walks. If you venture just south, you’ll find yourself in Balboa Park,
                a massive park with gardens, museums, and famous landmarks.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                The Arts and Entertainment Scene Is Unmatched
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Not all neighborhoods have a strong community spirit, but North Park certainly does,
                as it is home to families, retirees, artists, and young professionals alike. The
                streets are filled with local businesses, and music venues like the Observatory draw
                musical acts from all over the world.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Thanks to the neighborhood’s cultural mosaic, you’ll also get to experience
                festivals and other celebrations throughout the year. North Park is a neighborhood
                where there’s always something to do, and if you have a piece of North Park real
                estate to call your own, you’ll never miss out.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                There’s a Wide Variety of Homes
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                No matter what type of home you’re looking for, North Park likely features it. Even
                though the neighborhood has a fairly compact layout, there’s no shortage of
                single-family homes, many of which are historic, craftsman-style homes.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’re looking for a smaller space, you can also find townhouses and condominiums
                on the North Park real estate market. Some of these are part of the city’s “adaptive
                reuse” projects, which repurpose older buildings while preserving their classic
                architecture.
              </p>
              <h3 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Thinking About Making North Park Home?
              </h3>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As you can see, in North Park, attractive real estate offers an exciting opportunity
                for potential homeowners and investors alike. Don’t overlook the area’s bold,
                timeless appeal, and contact <ContactSlideoutLink text="The Selby Team" /> to get
                started on finding your new home!
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
